<template>
    <div>
        <div class="container">
            <div class="row print-header">
                <div class="col-4">
                    <img class="print-logo" src="/logo.svg"/>
                </div>
                <div class="col-4 text-center">
                    <span class="print-title">Check List ICP</span>
                </div>
                <div class="col-4 text-right">
                    <div class="print-address">
                        <b>GOLDEN CAR S.R.L.</b><br>
                        Via Cesare Battisti, 66<br>
                        Caramagna Piemonte CN Italy<br>
                        P.IVA IT02087210049
                    </div>
                </div>
            </div>
            <div class="print-main-info">
                <div class="row">
                    <div class="col-11">
                        <div class="row">
                            <div class="col col-1">
                                <span class="print-label">Cliente</span>
                                {{ progetto["codice_cliente"] }}
                            </div>
                            <div class="col col-2">
                                <span class="print-label">Progetto</span>
                                {{ progetto["nome"] }}
                            </div>
                            <div class="col col-3">
                                <span class="print-label">Codice commessa</span>
                                {{ progetto["codice_commessa"] }}
                            </div>
                            <div class="col col-3">
                                <span class="print-label">Note</span>
                                {{ pezzo["note"] }}
                            </div>
                            <div class="col col-3">
                                <span class="print-label">Part number</span>
                                {{ particolare['part_number'] }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-3">
                                <span class="print-label">Descrizione</span>
                                {{ progetto["descrizione"] }}
                            </div>
                            <div class="col col-3">
                                <span class="print-label">Part</span>
                                {{ particolare["nome"] }}
                            </div>
                            <div class="col col-2">
                                <span class="print-label">Data controllo</span>
                                {{ formatDate(pezzo["data_completamento"]) }}
                            </div>
                            <div class="col col-1">
                                <span class="print-label">Operatore</span>
                                <template v-if="pezzo.nomeOperatoreChiusura && pezzo.nomeOperatoreChiusura[0] && pezzo.nomeOperatoreChiusura[0].matricola">
                                    {{ pezzo.nomeOperatoreChiusura[0].matricola }}
                                </template>
                            </div>
                            <div class="col col-3">
                                <span class="print-label">Seriale</span>
                                {{pezzo["seriale"] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-1 qrcode-col">
                        <div id="qrcode" class="qrcode"></div>
                    </div>
                </div>
            </div>
            <div class="print-list">
                <div class="row">
                    <div class="col-6 print-list-col">
                        <ul>
                            <li v-for="(passo, index) in passi"
                                :key="passo['_id']">
                                <span v-if="passo['conforme']" class="mdi mdi-check-circle"></span>
                                <span v-if="passo['requireImage']" class="mdi mdi-camera" :class="{ color: passo['image_name'] ? '' : 'mdi-camera-disabled' }"></span>
                                <b>{{ index + 1 }}</b> {{ passo["nome"] }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <div class="print-photo">
                            <div class="row">
                                <div v-for="(passo, index) in passi"
                                     :key="passo['_id']"
                                     class="col col-6 pointer"
                                     v-if="passo['requireImage'] && passo.image_name"
                                     @click="downloadImage(passo, index + 1)"
                                >
                                    <img :src="endpointImage +'/'+passo.image_name" />
                                    <span class="mdi mdi-camera"></span> <b>{{ index + 1}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6 col-info-end">
                    GC PRO 018
                </div>
                <div class="col-6 col-info-end">
                    <i>Approvato da</i>
                </div>
            </div>
        </div>
        <div class="page-break"></div>
    </div>
</template>
<style>
.v-main {
    padding-left: 0 !important;
}

.v-main:not(.auth-pages) {
    padding-left: 0 !important;
}

.print-header {
    align-items: center !important;
}
.print-logo {
    height: 1.2cm !important;
}
.print-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 100%;
}
.print-address {
    color: #666;
    font-size: 60%;
    line-height: 1.1;
    display: inline-block;
}

.print-main-info {
    font-size: 70%;
    padding: .3cm;
    margin: .3cm 0;
}
.print-main-info .col {
    border: 1px solid #CCC;
    margin: -1px -1px 0 0;
    padding: .1cm .2cm 0cm .2cm;
}
.print-border-right {
    border-right: 1px solid #CCC;
}

.print-label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 55%;
    color: #666;
    display: block;
}

.qrcode-col {
    padding: 0;
    text-align: center;
}

.qrcode {
    display: inline-block;
    width: 1.6cm;
    height: 1.6cm;
    margin: 0 auto;
    background-color: #ccc;
}

.col-info-end {
    font-size: 70%;
    border-bottom: 1px solid #CCC;
    padding: .2cm .2cm .1cm .2cm;
}

.print-list ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.print-list li {
    font-size: 70%;
    display: flex;
}

.print-list li span, .print-list li b {
    display: inline-block;
    margin-right: .1cm ;
}

.print-list .col {
    padding-right: 0;
}

.print-list .mdi-check-circle {
    color: #27AE60;
}

.print-list .mdi-close-circle {
    color: #CB4335;
}

.print-list .mdi-camera {
    color: #2980B9;
}

.print-photo {
    padding: 0 .2cm .3cm .5cm;
    margin-top: .3cm;
    font-size: 70%;
}

.print-photo .col {
    padding: .1cm;
}

.print-photo img {
    display: block;
    width: 100%;
}

.page-break {
    page-break-after: always;
}

header {
    display: none !important;
}
nav {
    display: none !important;
}
.v-main {
    padding: 0 !important;
    padding-left: 0 !important;
}
.theme--light.v-application {
    background: white !important;
}
.v-application--wrap {
    min-height: 0;
}
.mdi-camera-disabled {
    color: #ccc !important;
}
</style>
<script>
import apiProgetti from "@/js/pages/progetti";
import apiPezzi from "@/js/pages/pezzi";
import { momentDate } from "@/js/formatDate";
import {generalFunction} from "@/js/generalFunction";

export default {
    name: "stampaPezzo",
    data() {
        return {
            pezzi: [],
            token: this.$cookies.get("token_goldencar"),
            ruolo: this.$store.state.ruolo,
            id: this.$route.params.id,
            progetto: {},
            particolare: {},
            pezzo: {},
            passi: [],
            passo: {},
            isLoading: false,
            endpointImage: process.env.VUE_APP_ENDPOINT_API
        };
    },
    async created() {
        this.progetto = await apiProgetti.getOneFromPezzo(this.token, this.id);
        this.particolare = this.progetto['particolari'];
        await this.reloadLista(true);

        let title = 'GoldenCar-ICP';
        if (this.progetto && this.progetto.nome) title += '-'+this.progetto.nome
        if (this.particolare && this.particolare.part_number) title += '-'+this.particolare.part_number
        if (this.pezzo && this.pezzo.seriale) title += '-'+this.pezzo.seriale;
        document.title = title;
    },
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/qrcodejs@1.0.0/qrcode.min.js';
        script.async = true;
        document.head.appendChild(script);

        let page = this;
        setTimeout(function () {
            new QRCode("qrcode", {
                text: "https://goldencar.edigital.ovh/s/"+page.id,
                width: 60,
                height: 60
            });
        }, 100);
    },
    methods: {
        formatDate(date) {
            if (date) return momentDate(date);
            return "";
        },
        async reloadLista(firstLoad = false) {
            this.pezzo = await apiPezzi.getOne(this.token, this.id);
            this.pezzo["passi"].push({
                _id: "altro",
                conforme: true,
                nome: "Altro",
                images: this.pezzo["images"],
                note: this.pezzo["note"],
            });
            this.imagesAltro = this.pezzo["images"] || [];
            this.passi = this.pezzo["passi"] || [];
            this.imageUploaded = undefined;
            this.noteAltro = this.pezzo["note"] || "";

            if (firstLoad)
                this.passo = this.passi.find((x) => x["conforme"] === undefined) || {};
        },
        async downloadImage(passo, index) {
            if (passo.image_name) {
                let extension = await generalFunction.getExtension(passo.image_name);

                fetch(this.endpointImage + '/' + passo.image_name)
                    .then(response => response.blob())
                    .then(blob => {
                        let link = document.createElement('a');

                        let title = 'GoldenCar';
                        if (this.particolare && this.particolare.part_number) title = this.particolare.part_number;
                        if (this.pezzo && this.pezzo.seriale) title += '-' + this.pezzo.seriale;
                        if (index) title += '-' + index;

                        link.href = window.URL.createObjectURL(new Blob([blob]));
                        link.download = title + '.jpg';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
            }
        }
    }
};
</script>